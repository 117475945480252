$(function() {
    var context = pwNamespace('PEAKWORK.FORM.AUTOCOMPLETE');

    context.init = function(element) {
        var $element = $(element);

        if ($element.hasClass('js-form-autocomplete-init')) {
            return;
        }

        $element.addClass('js-form-autocomplete-init');
        $element.select2({
            minimumInputLength: parseInt($element.data('minimuminputlength'), 10)
            ,allowClear: $element.data('allowclear')
            ,formatNoMatches: $element.data('formatnomatches')
            ,formatSearching: $element.data('formatsearching')
            ,formatInputTooShort: $element.data('formatinputtooshort')
            ,ajax: {
                url: $element.data('searchroute')
                ,dataType: 'json'
                ,quietMillis: 250
                ,data: function (term, page) {
                    var params = $element.data('query-params-autocomplete') ? $element.data('query-params-autocomplete') : {};
                    if ($element.data('query-params-autocomplete-search')) {
                        $.extend(params, $element.data('query-params-autocomplete-search'));
                    }
                    params.q = term;
                    return params;
                }
                ,results: function (data, page) {
                    return {
                        results: data
                    };
                }
                ,cache: true
            }
            ,initSelection: function(element, callback) {
                var $initElement = $(element)
                    ,id = $initElement.val()
                    ,params;

                if (id !== "") {
                    params = $initElement.data('query-params-autocomplete') ? $initElement.data('query-params-autocomplete') : {};
                    if ($element.data('query-params-autocomplete-get')) {
                        $.extend(params, $element.data('query-params-autocomplete-get'));
                    }
                    params.id = id;

                    $.ajax({
                        url: $initElement.data('getroute')
                        ,dataType: 'json'
                        ,data: params
                    }).done(function(data) {
                        callback(data);
                    });
                }
            }
            ,dropdownCssClass: "bigdrop"
            ,escapeMarkup: function (m) {
                return m;
            }
        });
    };


    /**
     * Initialize autocomplete for all fields which are not part of a collection template.
     */
    $.each($('.js-form-autocomplete'), function (idx, collection) {
        var $element = $(this)
            ,isTemplate = $element.closest('.js-collection-template').length > 0;

        if (false === isTemplate) {
            PEAKWORK.FORM.AUTOCOMPLETE.init($element);
        }
    });
});
