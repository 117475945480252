var PeakworkBackendHelper = (function () {
    var instance;
    
    /**
     * Create a instance.
     * @returns {PwsAjaxQueueManager}
     */
    this.createInstance = function() {
        var object = this;
        return object;
    };
    
    /**
     * Returns a html snippet to render a loader.
     * @param {String} description
     * @param {String} title
     * @returns {String}
     */
    this.createLoader = function(description, title) {
        var html = '';
        
        html += '<div class="row">' +
            '<div class="col-lg-12 text-center">' +
                '<div class="splash-title">';
    
        if ('string' === typeof title) {
            html += '<h1>' + title + '</h1>';
        }
        if ('string' === typeof description) {
            html += '<p>' + description + '</p>';
        }
        
        html += '<img src="' + PeakworkBackend.bundlesDir +
            'peakworkbackendbasis/images/loading-bars.svg" width="64" height="64" />';
        
        html += '</div>' +
            '</div>' +
        '</div>';

        return html;
    };
    
    return {
        getInstance: function () {
            if (!instance) {
                instance = createInstance();
            }

            return instance;
        },
        
        parseFormParamsToKeyValue: function(form) {
            var params = form.serializeArray()
                ,length = params.length
                ,i
                ,parsedValues = {};
                
            if (length < 1) {
                return parsedValues;
            }

            for (i = 0; i < length; i++) {
                if ('' === params[i].value || -1 !== params[i].name.indexOf('selectItem')) {
                    continue;
                }

                if ('undefined' !== typeof parsedValues[params[i].name]) {
                    parsedValues[params[i].name] += ',' + params[i].value;
                } else {
                    parsedValues[params[i].name] = params[i].value;
                }
            }

            return parsedValues;
         }
    };
})();