(function () {
    var context = pwNamespace('PEAKWORK.BACKEND.NAVIGATION');

    context.Menu = (function () {
        var instance = null;

        var injection = function () {
            return {};
        };

        var initInstance = function () {
            /* private */
            var me = {
                config: {
                    navigationSelector: '.js-metis-menu'
                    ,filterSelector: '.js-peakwork-navigationSearch'
                }
                ,init: function () {
                    me.initMetisMenu();
                    me.initNavigationSearch();

                }
                ,initMetisMenu: function() {
                    $(me.config.navigationSelector).metisMenu();
                }
                ,initNavigationSearch: function() {
                    var me = this
                        ,menues = $(me.config.navigationSelector)
                        ,i = 0
                        ,menuesLength = menues.length
                        ,$menu
                        ,$navSearch;

                    if (0 === menuesLength) {
                        return;
                    }

                    for (i; i < menuesLength; i++) {
                        $menu = $(menues[i]);
                        $navSearch = $($menu.prev(me.config.filterSelector));
                        if (1 !== $navSearch.length) {
                            continue;
                        }

                        $navSearch.find('input').keyup(function(){
                            var $input = $(this)
                                ,needle = $input.val()
                                ,subs = $menu.find('li')
                                ,name
                                ,minChars = $input.data('minchars')
                                ,openMatched = $input.data('openmatched');

                            if (needle.length >= minChars) {
                                $input.addClass('not-empty');
                                subs.each(function(){
                                    var $item = $(this);
                                    name = $(this).find('a').text();
                                    if(name.toLowerCase().indexOf(needle.toLowerCase()) === -1){
                                        $item.closest('li').addClass('hidden');
                                        if (true === openMatched) {
                                            $item.removeClass('active').find('.collapse').removeClass('in');
                                        }
                                    } else {
                                        $item.closest('li').removeClass('hidden');
                                        if (true === openMatched) {
                                            $item.addClass('active').find('.collapse').addClass('in');
                                        }
                                    }
                                });
                            } else {
                                $input.removeClass('not-empty');
                                subs.removeClass('hidden');
                            }
                        });
                    }
                }
            };

            /* public */
            var baseObj = {};

            /* attach public functions to singleton */
            $.each(injection.call(me, me), function (idx, prop) {
                baseObj[idx] = prop;
            });

            /* execute init method */
            me.init();

            return baseObj;
        };

        return {
            inject: function (inject) {
                injection = inject;
            }
            ,getInstance: function () {
                if (instance === null) {
                    instance = initInstance();
                }
                return instance;
            }
        };
    })();
})();

$(document).ready(function () {
    PEAKWORK.BACKEND.NAVIGATION.Menu.getInstance();
});
