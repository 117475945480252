/**
 * returns a context to the given namespace
 * creates a new namespace if given namespace does not exist
 * @author Jan Hoffmann <jan.hoffmann@peakwork.com>
 * @param {string} namspace separated by dots
 * @returns {object}
 */
function pwNamespace(namspace) {
    var namespaceComponents = namspace.split('.').map(function (val) { 
            return new String(val).toUpperCase(); 
        })
        ,context = window
        ,i = 0;
        
    for (; i < namespaceComponents.length; i++) {
        context[namespaceComponents[i]] = context[namespaceComponents[i]] || {};
        context = context[namespaceComponents[i]];
    }
    
    return context;
}
