$(function() {
    var $loginAsForm = $('#header-loginas-form')
        ,$loginAsFormAlert = $('.js-header-loginas-alert')
        ,$loginAsFormSubmitBtn = $loginAsForm.find('.js-header-loginas-submit')
        ,$loginAsFormRollbackLink = $('.js-header-loginas-rollback');

    $loginAsForm.on('click', '.js-header-loginas-submit', function(event) {
        event.preventDefault();
        $loginAsFormSubmitBtn.prop('disabled', true);
        $loginAsFormAlert.addClass('hidden');
        $.ajax({
            url: $loginAsForm.attr('action'),
            method: 'POST',
            data: {
                username: $('#login_as_username').val(),
                redirectUri: 'peakwork_sso_private_home'
            },
            dataType: 'json',
            success: function(response) {
                window.location = Routing.generate('peakwork_sso_private_home');
            },
            error: function(response) {
                $loginAsFormAlert
                    .html(response.responseJSON.errorMsg)
                    .removeClass('hidden');

                $loginAsFormSubmitBtn.prop('disabled', false);
            }
        });
    });

    $loginAsFormRollbackLink.on('click', function(event) {
        event.preventDefault();

        $.ajax({
            url: $loginAsFormRollbackLink.attr('href'),
            method: 'POST',
            dataType: 'json',
            data: {
                redirectUri: 'peakwork_sso_private_home'
            },
            success: function(response) {
                window.location = Routing.generate('peakwork_sso_private_home');
            },
            error: function(response) {
                $loginAsFormAlert
                    .html(response.responseJSON.errorMsg)
                    .removeClass('hidden');

                $loginAsFormSubmitBtn.prop('disabled', false);
            }
        });

    });
});