var PeakworkAdminToolBackendInit = (function() {
    return {
        /**
         * Add special class to minimalize page elements when screen is less than 768px
         */
        setBodySmall: function() {
            if ($(window).width() < 769) {
                $('body').addClass('page-small');
            } else {
                $('body').removeClass('page-small');
                $('body').removeClass('show-sidebar');
            }
        },
        
        /**
         * Handle minimalize sidebar menu
         */
        hideMenuEvent: function() {
            $('.hide-menu').click(function(event) {
                event.preventDefault();
                if ($(window).width() < 769) {
                    $('body').toggleClass('show-sidebar');
                } else {
                    $('body').toggleClass('hide-sidebar');
                }
            });
        },
        
        /**
         * Initialize iCheck plugin
         */
        initICheckPlugin: function() {
            $('.i-checks').iCheck({
                checkboxClass: 'icheckbox_square-green',
                radioClass: 'iradio_square-green'
            });
        },
        
        /**
         * Adds smoothy scrolling for anchor links.
         */
        smoothyAnchorSlider: function() {
            $('body').on('click', 'a.anchor', function (event) {
                var link = $(this), anchorName = link.attr('href').replace('#', ''),
                    anchorItem = $('a[name="' + anchorName + '"]');

                if (anchorItem && 0 !== anchorItem.length) {
                    event.preventDefault();
                    $('html, body').animate({
                        scrollTop: anchorItem.offset().top
                    }, 500);
                    
                    return false;
                }
            });
        },
        
        /**
         * Function for collapse hpanel
         */
        showHidePanelEvent: function() {
            $('body').on('click', '.showhide', function (event) {
                var hpanel = $(this).closest('div.hpanel'),
                    icon = $(this).find('i:first'),
                    body = hpanel.find('div.panel-body'),
                    footer = hpanel.find('div.panel-footer');
                
                event.preventDefault();
                body.slideToggle(300);
                footer.slideToggle(200);

                // Toggle icon from up to down
                icon.toggleClass('fa-chevron-up').toggleClass('fa-chevron-down');
                hpanel.toggleClass('').toggleClass('panel-collapse');
                setTimeout(function () {
                    hpanel.resize();
                    hpanel.find('[id^=map-]').resize();
                }, 50);
            });
            
            /* handle initial hide */
            $('.showhide[data-hide="true"]').each(function() {
                $(this).trigger('click');
            });
        },
        
        /**
         * Function for close hpanel
         */
        closePanelEvent: function() {
            $('body').on('click', '.closebox', function (event) {
                var closeBtn = $(this);
                
                event.preventDefault();
                if (closeBtn.hasClass('closebox-confirm')) {
                    closeBtn = closeBtn.trigger('beforeclosebox', function(closeBtn) {
                        closeBtn.closest('div.hpanel').remove();
                    });
                } else {
                    closeBtn.closest('div.hpanel').remove();
                }
            });
        },
        
        /**
         * Function for small header
         */
        smallHeaderEvent: function() {
            $('body').on('click', '.small-header-action', function(event) {
                var icon = $(this).find('i:first')
                    ,breadcrumb = $(this).parent().find('#hbreadcrumb');
                    
                event.preventDefault();
                $(this).parent().parent().parent().toggleClass('small-header');
                breadcrumb.toggleClass('m-t-lg');
                icon.toggleClass('fa-arrow-up').toggleClass('fa-arrow-down');
            });
        },
        
        /**
         * Set minimal height of #wrapper to fit the window
         */
        fixWrapperHeight: function() {
            // Get and set current height
            var headerH = 62
                ,navigationH = $('#navigation').height()
                ,contentH = $('.content').height();

            // Set new height when contnet height is less then navigation
            if (contentH < navigationH) {
                $('#wrapper').css('min-height', navigationH + 'px');
            }

            // Set new height when contnet height is less then navigation and navigation is less then window
            if (contentH < navigationH && navigationH < $(window).height()) {
                $('#wrapper').css('min-height', $(window).height() - headerH + 'px');
            }

            // Set new height when contnet is higher then navigation but less then window
            if (contentH > navigationH && contentH < $(window).height()) {
                $('#wrapper').css('min-height', $(window).height() - headerH + 'px');
            }
        },
        
        /**
         * Initialize tooltips
         */
        initTooltips: function() {
            $('body').tooltip({
                selector: '[data-toggle=tooltip]'
            });
        },
        
        /**
         * Loads content via ajax for defined containers (data-modus="loadcontent")
         */
        initLoadContent: function() {
            $('[data-modus="loadcontent"]').each(function(index, item) {
                var el = $(item), url = el.data('url')
                    ,eventTrigger = el.data('trigger');
                
                if ('undefined' === typeof url) {
                    return false;
                }
                
                $.ajax({
                    url: url,
                    method: 'GET',
                    dataType: 'html',
                    beforeSend: function() {
                        el.html(PeakworkBackendHelper.getInstance().createLoader());
                    },
                    success: function(response) {
                        el.html(response);
                        if ('undefined' !== typeof eventTrigger) {
                            $('body').trigger(eventTrigger, [el]);
                        }
                        
                    },
                    error: function() {
                        el.remove();
                    }
                });
            });
        }
    };
})();

$(document).ready(function () {
    PeakworkAdminToolBackendInit.setBodySmall();
    PeakworkAdminToolBackendInit.hideMenuEvent();
    $('#side-menu').metisMenu(); // Initialize metsiMenu plugin to sidebar menu
    PeakworkAdminToolBackendInit.initICheckPlugin();
    $('.animate-panel').animatePanel(); // Initialize animate panel function
    PeakworkAdminToolBackendInit.showHidePanelEvent();
    PeakworkAdminToolBackendInit.closePanelEvent();
    PeakworkAdminToolBackendInit.smallHeaderEvent();
    PeakworkAdminToolBackendInit.fixWrapperHeight();
    PeakworkAdminToolBackendInit.initTooltips();
    PeakworkAdminToolBackendInit.smoothyAnchorSlider();
    PeakworkAdminToolBackendInit.initLoadContent();
    
    $('[data-toggle=popover]').popover();
    // Move modal to body
    // Fix Bootstrap backdrop issu with animation.css
    $('.modal').appendTo('body');
});

$(window).bind('load', function () {
    // Remove splash screen after load
    $('.splash').css('display', 'none');
});

$(window).bind('resize click', function () {
    PeakworkAdminToolBackendInit.setBodySmall();

    // Waint until metsiMenu, collapse and other effect finish and set wrapper height
    setTimeout(function () {
        PeakworkAdminToolBackendInit.fixWrapperHeight();
    }, 300);
});


// Animate panel function
$.fn['animatePanel'] = function() {
    var element = $(this),
        effect = $(this).data('effect'),
        delay = $(this).data('delay'),
        child = $(this).data('child'),
        startAnimation = 0, start, panel;

    // Set default values for attrs
    if (!effect) {
        effect = 'zoomIn';
    }
    
    if (!delay) {
        delay = 0.06 ;
    } else {
        delay = delay / 10;
    }
    
    if (!child) {
        child = '.row > div';
    } else {
        child = '.' + child;
    }

    //Set defaul values for start animation and delay
    start = Math.abs(delay) + startAnimation;

    // Get all visible element and set opactiy to 0
    panel = element.find(child);
    panel.addClass('opacity-0');

    // Get all elements and add effect class
    panel = element.find(child);
    panel.addClass('animated-panel').addClass(effect);

    // Add delay for each child elements
    panel.each(function (i, elm) {
        var rounded = Math.round(start * 10) / 10;
        start += delay;
        $(elm).css('animation-delay', rounded + 's');
        // Remove opacity 0 after finish
        $(elm).removeClass('opacity-0');
    });
};